@import "./style-functions.scss";

@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/custom/Pacifico-Regular.woff2") format("woff2"),
  url("../fonts/custom/Pacifico-Regular.woff") format("woff");
}

#main-bg-top {
  position: fixed;
  top: 270px;
  left: -200px;
  z-index: 0;

  @include custom(680) {
    display: none;
  }
}

#main-bg-bottom {
  position: fixed;
  right: -80px;
  bottom: 100px;
  z-index: 0;

  img {
    width: 200px;
  }
}

header nav .nav-menu > ul > li {
  font-family: "Pacifico";
  @include font-size(34px);

  @include custom(1560) {
    @include font-size(30px);
  }
  @include custom(1430) {
    @include font-size(26px);
  }
  @include custom(1200) {
    @include font-size(24px);
  }
}

header .nav-access {
  width: 15%;

  @include sm {
    width: 100%;
  }
}

footer .footer-infos,
footer .footer-links,
footer .footer-copy {
  position: relative;
  z-index: 1;
}
footer .footer-infos {
  background-color: #edf8ea !important;
  border-radius: 30px;

  @include custom(1366) {
    border-radius: 0;
  }
}

footer .footer-copy {
  border-width: 1px !important;
}

.custom {
  margin: 0 80px;

  @include custom(1366) {
    margin: 0 !important;
  }

  .cover {
    position: relative;
    text-align: center;

    img {
      display: block;
      width: 100%;
      max-width: 1366px;
      object-fit: cover;
      object-position: 50% 50%;
      border-radius: 30px;

      @include custom(1366) {
        border-radius: 0;
      }

      @include sm {
        margin-top: 30px;
        height: 250px;
      }
    }
  }
}

.home .cover img {
  object-position: 100% 0;
}

.contact {
  .text {
    text-align: center;
    width: 360px;
    margin: 20px auto 0;
  }
  .contact-text {
    margin-top: 40px !important;
  }
}

.payment-form {
  input {
    background-color: #edf8ea !important;
  }
  .payf-row {
    border-top: solid 1px #9ec797 !important;
  }
}

.shopping-cart {
  .layout-row {
    .layout-column-left {
      .cart-item {
        border-top: solid 1px #9ec797 !important;

        &:first-child {
          border-top: none !important;
        }
      }
    }
  }
}

.responsive-table {
  .table-row {
    background-color: #edf8ea !important;
  }
}

.variations {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;

  .title {
    margin-bottom: 10px;
    margin-top: 10px;
    @include font-size(16px);
  }

  .item {
    display: inline-block;
    margin: 0 5px;
  }

  img {
    opacity: 0.5;
    max-width: 90px;
    margin-left: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 6px 6px 0 0;
    @include transition(all ease-in-out 0.2s);

    &:hover {
      border-radius: 12px 12px 12px 0;
      cursor: pointer;
    }
  }

  .selected {
    border: 3px solid #449B23;
    opacity: 1;
  }

  .item {
    display: flex;
    flex-direction: column;
    max-width: 130px;

  }

  .tooltiptext {
    text-align: center;
  }
}
